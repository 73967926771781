<script>
export default {
	name: "GiftList",
	computed: {
		prizesList(){
			return this.$store.state.prizesList
		},
	},
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>